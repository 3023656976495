import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { graphql } from 'gatsby';

import SEO from '../components/Seo';
import Layout from '../components/layout';

const Designer = (props) => {
  const data = props.data.wordpressWpDesigner;

  return (
    <Layout>
      <SEO title={data.title} />
      <Row className='d-title'>
        <Col>
          <h2>{data.title}</h2>
          <h3 className='m-0'>{data.acf.sub_title}</h3>
        </Col>
      </Row>
      <Row className='d-banner'>
        <Col>
          <img src={data.acf.hero.source_url} className='img-fluid' />
        </Col>
      </Row>
      <Row className='d-snippet'>
        <Col dangerouslySetInnerHTML={{ __html: data.acf.designer_snippet }} />
      </Row>
      <Row className='d-secondary_img'>
        <Col>
          {data.acf.first_image && (
            <img src={data.acf.first_image.source_url} className='img-fluid' />
          )}
        </Col>
        <Col>
          {data.acf.Second_Image && (
            <img src={data.acf.Second_Image.source_url} className='img-fluid' />
          )}
        </Col>
      </Row>
      <Row className='d-content'>
        <Col dangerouslySetInnerHTML={{ __html: data.content }} />
      </Row>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($id: Int) {
    wordpressWpDesigner(wordpress_id: { eq: $id }) {
      title
      content
      acf {
        sub_title
        designer_snippet
        first_image {
          source_url
        }
        hero {
          source_url
        }
        Second_Image {
          source_url
        }
      }
    }
  }
`;

export default Designer;
